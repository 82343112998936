
  import { Component } from 'vue-property-decorator'
  import UserCard from '@/components/users/UserCard.vue'
  import UserTable from '@/components/users/UserTable.vue'
  import { GForm } from '@/components/forms/GForm'

@Component({
  components: { UserTable, UserCard },
})
  export default class SysopUsers extends GForm {
  onlineUsers: Array<any> = []
  isCardView = false
  intervalId: ReturnType<typeof setInterval> | null = null
  idProcess = null

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'person' } },
    })

    this.idProcess = process[0].id
    await this.fetchOnlineUsers()
    this.intervalId = setInterval(this.fetchOnlineUsers, 30000) // Llama cada 30s (30000 ms)
  }

  beforeDestroy () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  // Método para obtener usuarios en línea
  async fetchOnlineUsers () {
    const users = await this.fetchData({
      query: { name: 'find', model: 'User' },
      filter: { _and: [{ active: { _eq: true } }, { id: { _neq: this.user.id } }] },
      force: true,
    })

    await Promise.all(users.map(async user => {
      const photo = await this.getPhoto(user)

      if (user.person?.photo?.main) {
        user.person.photo.main = photo
      } else {
        user.person.photo = { main: photo }
      }
    }))

    this.onlineUsers = users.sort((a, b) => {
      if (a.userIsOnline && !b.userIsOnline) return -1
      if (!a.userIsOnline && b.userIsOnline) return 1

      if (a.lastSeen && b.lastSeen) {
        return b.lastSeen.isBefore(a.lastSeen) ? -1 : 1 // Cambiado para ordenar de más reciente a más antiguo
      }
      if (a.lastSeen && !b.lastSeen) return -1
      if (!a.lastSeen && b.lastSeen) return 1

      if (!a.lastSeen && !b.lastSeen) {
        return a.person.fullName.localeCompare(b.person.fullName)
      }

      return 0
    })
  }

  async getPhoto (user) {
    const { person } = user

    const file = (await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: person?.id } },
          { parameter: { process: { id: { _eq: this.idProcess } } } },
        ],
      },
    }))[0]

    return file?.file?.largeUrl
  }

  toggleView () {
    this.isCardView = !this.isCardView
  }
  }
