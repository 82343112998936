
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { User } from '@/entities/public'

  import initials from 'initials'

@Component
  export default class UserCard extends Vue {
  @Prop({ required: true }) user!: User

  get lastSeenFormatted () {
    if (!this.user.lastSeenLocal) return 'Desconocido'
    return dayjs(this.user.lastSeenLocal).format('DD/MM/YYYY HH:mm')
  }

  // Methods
  isPhoto (user) {
    return Boolean(user?.person?.photo?.main)
  }

  // Getters
  getInitials (user) {
    const { person } = user
    const name = person?.firstName + ' ' + person?.surname
    const alias = person.alias || person.socialReason
    return initials(alias || name || (person.email as string)).toUpperCase()
  }

  get activeTime () {
    const now = dayjs()
    if (!this.user.lastSeenLocal) return 'Desconocido'
    const lastSeen = dayjs(this.user.lastSeenLocal)

    const diffInMinutes = now.diff(lastSeen, 'minute')
    const diffInHours = now.diff(lastSeen, 'hour')
    const diffInDays = now.diff(lastSeen, 'day')
    const diffInMonths = now.diff(lastSeen, 'month')
    const diffInYears = now.diff(lastSeen, 'year')

    if (diffInMinutes < 1) {
      return 'Activo hace unos segundos'
    } else if (diffInMinutes < 60) {
      return `Activo hace ${diffInMinutes} minuto${diffInMinutes > 1 ? 's' : ''}`
    } else if (diffInHours < 24) {
      return `Activo hace ${diffInHours} hora${diffInHours > 1 ? 's' : ''}`
    } else if (diffInDays < 30) {
      return `Activo hace ${diffInDays} día${diffInDays > 1 ? 's' : ''}`
    } else if (diffInMonths < 12) {
      return `Activo hace ${diffInMonths} mes${diffInMonths > 1 ? 'es' : ''}`
    } else {
      return `Activo hace ${diffInYears} año${diffInYears > 1 ? 's' : ''}`
    }
  }

  get withOutTime () {
    const { activeTime } = this

    return activeTime === 'Desconocido'
  }

  getName (user) {
    const { person } = user
    const name = (person?.firstName || '') + ' ' + (person?.surname || '')

    return name.toUpperCase()
  }

  // Watchers
  }
